import { Delete } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import DischargeKeeperStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeKeeperStationForm';
import ViewMeasurementsButton from 'pages/SiteDetails/components/ViewMeasurementsButton';
import ConfigHistoricalOverviewButton from 'pages/SiteDetails/ConfigHistoricalOverviewButton';
import React, { FunctionComponent } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useDeleteStation, useStationDetails } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import { IStationTabProps } from 'pages/SiteDetails/components/types';

const DischargeKeeperStationTab: FunctionComponent<IStationTabProps> = ({ station_id }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleConfigHistoricalOverview = useGlobalStore(
		(state) => state.toggleConfigHistoricalOverview
	);

	const { mutate: deleteStation } = useDeleteStation();
	const { isLoading: stationLoading } = useStationDetails<DischargeKeeperStationDetails>(
		Number(selectedSiteId),
		station_id,
		{
			enabled: !!station_id
		}
	);

	if (stationLoading)
		return (
			<div className={'app flex h-full flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={50} thickness={4} />
			</div>
		);

	const handleStationDelete = () => {
		deleteStation(
			{ site_id: Number(selectedSiteId), station_id: Number(station_id) },
			{
				onSuccess: () => {
					toast.success(t('STATION_DELETED'));
					queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
				}
			}
		);
	};
	const handleViewMeasurementsClick = () => {
		navigate(`/${currentOrganization.id}/measurements/`, {
			state: {
				siteId: selectedSiteId,
				stationId: station_id
			}
		});
	};

	const handleConfigOverviewClick = () => {
		toggleConfigHistoricalOverview(true, Number(station_id), Number(selectedSiteId));
	};

	return (
		<div>
			<div className={'flex w-full flex-row flex-wrap justify-between gap-2'}>
				<div className={'flex flex-wrap gap-2'}>
					{' '}
					{station_id && <ViewMeasurementsButton handleClick={handleViewMeasurementsClick} />}
					{station_id && (
						<ConfigHistoricalOverviewButton
							handleClick={handleConfigOverviewClick}></ConfigHistoricalOverviewButton>
					)}
				</div>
				{station_id && (
					<div className={'flex items-center gap-1 align-middle'}>
						<div className={'flex w-full justify-end text-xs'}>
							{t('ID')}: {station_id}
						</div>
						<IconWithTooltip
							title={`${t('DELETE')} ${t('STATION').toLowerCase()}`}
							icon={
								<Delete
									className={'text-danger'}
									onClick={() => {
										toggleConfirmationDialog({
											confirmAction: handleStationDelete,
											dialogOpen: true,
											type: 'confirmation',
											message: t('DELETE_PROMPT') + '?'
										});
									}}
								/>
							}
						/>
					</div>
				)}
			</div>
			<DischargeKeeperStationForm station_id={Number(station_id)} CPFastFlowForm={false} />
		</div>
	);
};
export default DischargeKeeperStationTab;
