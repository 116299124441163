import { Add, CheckCircleOutline, Visibility, VisibilityOff } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import RatingCurveForm from 'components/Measurements/RatingCurve/RatingCurveForm';
import { StyledDataGrid } from 'components/shared/DataGrid/StyledDataGrid';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import { capitalizeFirstLetter } from 'helper/helperFunctions';
import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import React, { FunctionComponent, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useDeleteRatingCurve, useRatingCurves } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import StationService from 'services/StationService/StationService';

const RatingCurveDataGrid: FunctionComponent = () => {
	const { t } = useTranslation();

	const { site_id, station_id } = useGlobalStore((state) => state.ratingCurveData);
	const setRatingCurveFormData = useGlobalStore((state) => state.setRatingCurveFormData);
	const ratingCurveFormData = useGlobalStore((state) => state.ratingCurveFormData);
	const ratingCurveData = useGlobalStore((state) => state.ratingCurveData);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const setRatingCurveData = useGlobalStore((state) => state.setRatingCurveData);

	const { data: ratingCurves } = useRatingCurves(Number(site_id), Number(station_id), {
		enabled: !!site_id && !!station_id
	});
	const { mutate: deleteRatingCurve } = useDeleteRatingCurve();

	const curvesGridData = useMemo(() => {
		return ratingCurves?.map((curve) => {
			return {
				...curve,
				isVisible:
					curve.active ||
					ratingCurveData.ratingCurves?.find((item) => item.id === curve.id)?.isVisible
			};
		});
	}, [ratingCurves]);

	const setRatingCurveAsPrimary = (curve: DischargeStationCurve) => {
		const data = { ...curve };
		data.active = true;

		StationService.updateDischargeCurve({
			site_id: Number(site_id),
			station_id: Number(station_id),
			curve: data
		}).then(() => {
			queryClient.invalidateQueries(queryKeys.rating_curves);
		});
	};

	const columns: GridColDef[] = [
		{
			field: 'model',
			headerName: `${t('NAME')}`,
			flex: 1,
			minWidth: 120,
			renderCell: (params) => {
				return <span className={params.row.active ? 'font-bold' : ''}>{params.row.model}</span>;
			}
		},
		{
			field: 'type',
			headerName: `${t('TYPE')}`,
			flex: 0.8,
			minWidth: 100,
			renderCell: (params) => {
				return (
					<span className={params.row.active ? 'font-bold' : ''}>
						{capitalizeFirstLetter(params.row.type)}
					</span>
				);
			}
		},
		{
			field: 'equation',
			headerName: `${t('CURVE')}`,
			flex: 1.2,
			minWidth: 160,
			renderCell: (params) => {
				switch (params.row.type) {
					case 'exponential':
						return (
							<span className={params.row.active ? 'font-bold' : ''}>
								Q(h) = {params.row.parameters[0]} * (h {params.row.parameters[1] >= 0 ? '+' : '-'}{' '}
								{Math.abs(Number(params.row.parameters[1]))})<sup>{params.row.parameters[2]}</sup>
							</span>
						);
					case 'quadratic':
						return (
							<span className={params.row.active ? 'font-bold' : ''}>
								Q(h) = {params.row.parameters[0]}h<sup>2</sup> + {params.row.parameters[1]}h +{' '}
								{params.row.parameters[2]}
							</span>
						);
					case 'cubic':
						return (
							<span className={params.row.active ? 'font-bold' : ''}>
								Q(h) = {params.row.parameters[0]}h<sup>3</sup> + {params.row.parameters[1]}h
								<sup>2</sup> + {params.row.parameters[2]}h + {params.row.parameters[3]}
							</span>
						);
					default:
						return null;
				}
			}
		},
		{
			field: 'actions',
			headerName: `${t('ACTIONS')}`,
			flex: 1,
			minWidth: 130,
			renderCell: (data) => {
				return (
					<div className={'flex flex-wrap gap-2'} onClick={(e) => e.stopPropagation()}>
						<PermissionView
							requiredPermissions={['create_measurements', 'validate_measurements']}
							showFallbackComponent={false}>
							<>
								<div>
									<IconWithTooltip
										title={`${t('SET_AS_PRIMARY')}`}
										icon={
											<IconButton
												disabled={data.row.active}
												className={'py-2 px-0 text-secondary disabled:text-gray-500'}
												onClick={() =>
													toggleConfirmationDialog({
														dialogOpen: true,
														type: 'confirmation',
														message: t('SET_AS_PRIMARY_CURVE', { curveName: data.row.model }),
														confirmAction: () => setRatingCurveAsPrimary(data.row)
													})
												}>
												<CheckCircleOutline fontSize={'small'} />
											</IconButton>
										}
									/>
								</div>

								<div>
									<IconWithTooltip
										title={data.row.isVisible ? `${t('HIDE')}` : `${t('SHOW')}`}
										icon={
											<IconButton
												className={'py-2 px-0 text-secondary'}
												onClick={() => {
													const visibleCurves = curvesGridData ? [...curvesGridData] : [];
													visibleCurves.forEach((curve) => {
														if (curve.id === data.row.id) curve.isVisible = !curve.isVisible;
													});

													setRatingCurveData({
														station_id: Number(station_id),
														site_id: Number(site_id),
														ratingCurves: visibleCurves
													});
												}}>
												{!data.row.isVisible ? (
													<Visibility fontSize={'small'} />
												) : (
													<VisibilityOff fontSize={'small'} />
												)}
											</IconButton>
										}
									/>
								</div>
								<div>
									<IconWithTooltip
										title={`${t('EDIT')}`}
										icon={
											<IconButton
												className={'py-2 px-0 text-secondary disabled:text-gray-500'}
												onClick={() =>
													setRatingCurveFormData({
														rating_curve: data.row,
														formToggled: true
													})
												}>
												<EditIcon className={'text-secondary'} fontSize={'small'} />
											</IconButton>
										}
									/>
								</div>
								<div>
									<IconWithTooltip
										title={`${t('DELETE')}`}
										icon={
											<IconButton
												disabled={data.row.active || !data.row.id}
												className={'py-2 px-0 text-danger disabled:text-gray-500'}
												onClick={() =>
													toggleConfirmationDialog({
														type: 'confirmation',
														dialogOpen: true,
														message: t('DELETE_RATING_CURVE_PROMPT', { curveName: data.row.model }),
														confirmAction: () => {
															deleteRatingCurve(
																{
																	site_id: Number(site_id),
																	station_id: Number(station_id),
																	rating_curve_id: data.row.id
																},
																{
																	onSuccess: () => {
																		queryClient.invalidateQueries(queryKeys.rating_curves);
																		toast.success(
																			t('RATING_CURVE_DELETED', { curveName: data.row.model })
																		);
																	}
																}
															);
														}
													})
												}>
												<DeleteOutlineIcon fontSize={'small'} />
											</IconButton>
										}
									/>
								</div>
							</>
						</PermissionView>
					</div>
				);
			}
		}
	];

	return (
		<div className={'flex flex-col gap-6'}>
			<StyledDataGrid columns={columns} rows={curvesGridData ?? []} autoHeight></StyledDataGrid>
			<PermissionView requiredPermissions={'manage_sites'} showFallbackComponent={false}>
				<div className={'flex w-full justify-end '}>
					<Button
						className={
							'w-18 flex items-center justify-center self-end align-middle normal-case disabled:bg-gray-400'
						}
						startIcon={<Add />}
						disabled={ratingCurveFormData.formToggled}
						variant={'contained'}
						onClick={() => {
							if (!ratingCurveFormData.formToggled) {
								setRatingCurveFormData({
									rating_curve: null,
									formToggled: true
								});
								setRatingCurveData({
									station_id: Number(station_id),
									site_id: Number(site_id),
									ratingCurves: curvesGridData
										? [
												...curvesGridData,
												{
													model: '',
													type: DischargeStationCurve.type.EXPONENTIAL,
													active: false,
													parameters: [],
													isVisible: true
												}
										  ]
										: [
												{
													model: '',
													type: DischargeStationCurve.type.EXPONENTIAL,
													active: false,
													parameters: [],
													isVisible: true
												}
										  ]
								});
							}
						}}>
						{t('ADD')}
					</Button>
				</div>
			</PermissionView>
			{ratingCurveFormData.formToggled && <RatingCurveForm />}
		</div>
	);
};
export default RatingCurveDataGrid;
