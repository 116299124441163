import { InsertPhoto } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import classNames from 'classnames';
import { IAnyParamsDataGrid } from 'components/Measurements/types';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { format } from 'date-fns';
import { useGlobalStore } from 'global-state/useStore';
import { convertToUsersTimezone } from 'helper/helperFunctions';
import { CustomColumnMenu } from 'pages/MeasurementsPage/components/CustomColumnMenu';
import { CustomToolbar } from 'pages/MeasurementsPage/components/CustomToolbar';
import { StyledMeasurementGrid } from 'pages/MeasurementsPage/components/StyledMeasurementsGrid';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const AnyParamsDataGrid: FunctionComponent<IAnyParamsDataGrid> = ({ measurements }) => {
	const { t } = useTranslation();

	const toggleMeasurementImageVideoModal = useGlobalStore(
		(state) => state.toggleMeasurementImageVideoModal
	);
	const { timezone_display } = useGlobalStore((state) => state.userInfo);

	const [paginationModel, setPaginationModel] = useState({
		pageSize: 10,
		page: 0
	});

	const handlePaginationChange = (e: any) => {
		setPaginationModel(e);
	};

	const [filteredRows, setFilteredRows] = useState(measurements);
	const [selectedName, setSelectedName] = useState('');

	useEffect(() => {
		if (selectedName && measurements) {
			setFilteredRows(measurements.filter((row) => row.paramName === selectedName));
		} else {
			setFilteredRows(measurements);
		}
	}, [selectedName, measurements]);

	const handleNameChange = useCallback((event: any) => {
		setSelectedName(event.target.value);
	}, []);

	const uniqueNames = [...new Set(measurements?.map((row) => row.paramName))];

	const displayAnyParamSelect = () => {
		return (
			<FormControl variant="standard" className={'m-4 w-60'}>
				<InputLabel className={'w-96'}>{t('PARAM_NAME')}</InputLabel>
				<Select value={selectedName} onChange={(e) => handleNameChange(e)} label="Name">
					<MenuItem value="">
						<em>{t('ALL')}</em>
					</MenuItem>
					{uniqueNames?.map((name) => (
						<MenuItem key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

	const columns = [
		{
			field: 'disch_meas_id',
			headerName: t('MEASUREMENT_ID')
		},
		{
			field: 'paramName',
			headerName: t('NAME'),
			flex: 1,
			minWidth: 150
		},
		{
			field: 'date_time',
			headerName: `${t('TIME')}`,
			flex: 1,
			minWidth: 150,
			renderCell: (value: any) => {
				return (
					<div>
						{format(
							convertToUsersTimezone(
								value.row.date_time,
								{ timezone_offset: value.row.utc_offset },
								timezone_display
							),
							'yyyy-MM-dd HH:mm'
						)}
					</div>
				);
			}
		},
		{
			field: 'value',
			headerName: t('VALUE'),
			minWidth: 150,
			flex: 1
		},

		{
			field: 'actions',
			headerName: `${t('ACTION')}`,
			filterable: false,
			minWidth: 150,
			disableColumnMenu: true,
			disableExport: true,
			sortable: false,
			hideable: false,
			editable: false,
			renderCell: (data: any) => {
				return (
					<div className={'flex flex-wrap gap-2'}>
						<div
							onClick={() =>
								!!data.row.image && data.row.image !== '' &&
								toggleMeasurementImageVideoModal({
									modalType: 'image',
									imageSrc: data.row.image,
									videoSrc: '',
									measurement: data.row
								})
							}>
							<IconWithTooltip
								title={`${t('MEASUREMENT_PHOTO')}`}
								icon={
									<InsertPhoto
										className={classNames(
											{ 'text-secondary': !!data.row.image && data.row.image !== '' },
											{
												'cursor-auto text-gray-300 hover:opacity-100': !data.row.image || data.row.image === ''
											}
										)}
									/>
								}
							/>
						</div>
					</div>
				);
			}
		}
	];

	return (
		<div>
			{displayAnyParamSelect()}
			<StyledMeasurementGrid
				columns={columns}
				rows={filteredRows ?? []}
				rowHeight={60}
				className={' shadow-xl'}
				pagination
				paginationMode={'client'}
				paginationModel={paginationModel}
				onPaginationModelChange={handlePaginationChange}
				pageSizeOptions={[10, 25, 50, 100]}
				initialState={{
					columns: {
						columnVisibilityModel: {
							measurement_id: false,
							discharge_q1: false,
							discharge_q2: false,
							discharge_q3: false,
							camera_ok: false,
							displacement: false
						}
					},
					sorting: {
						sortModel: [{ field: 'paramName', sort: 'asc' }]
					}
				}}
				/*				loading={paginatedMeasurementsFetching}*/
				hideFooterSelectedRowCount
				slots={{
					loadingOverlay: LinearProgress,
					columnMenu: CustomColumnMenu,
					toolbar: CustomToolbar
				}}
				autoHeight
			/>
		</div>
	);
};
export default AnyParamsDataGrid;
