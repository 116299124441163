import { useGlobalStore } from 'global-state/useStore';
import DischargeKeeperStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeKeeperStationForm';
import DischargeStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeStationForm';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfigFineTune = () => {
	const { t } = useTranslation();
	const selectedStation = useGlobalStore((state) => state.cloudProcessing.selectedStation);
	return (
		<div className={'flex h-max w-full flex-col gap-4 align-bottom text-black  '}>
			<div>
				<div className={'font-bold'}>{t('STATION_CONFIG_FINE_TUNE')}</div>
				<div className="text-sm text-gray-500 ">Note: Editing this section is optional.</div>
			</div>
			{selectedStation && selectedStation.station_type === 'DISCHARGE' && (
				<DischargeStationForm station_id={selectedStation.station_id} CPFastFlowForm={true} />
			)}
			{selectedStation && selectedStation.station_type === 'DISCHARGE_KEEPER' && (
				<DischargeKeeperStationForm station_id={selectedStation.station_id} CPFastFlowForm={true} />
			)}
		</div>
	);
};
export default ConfigFineTune;
