import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Autocomplete, List } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import UsersService from 'services/UsersService/UsersService';
import { permissions } from 'helper/helperData';

import { useFormik } from 'formik';
import { useAuth } from 'rq/hooks/authHook';
import { IInviteUsersModalProps } from './types';
import { useCreateUserMembership } from 'rq/hooks/userHook';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import { useGlobalStore } from 'global-state/useStore';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';

const InviteUsersModal: FunctionComponent<IInviteUsersModalProps> = ({
	open,
	handleModalClose,
	handleUpdate
}) => {
	const { t } = useTranslation();

	const { data: me } = useAuth();

	const currentUserId = me?.id;

	const users = queryClient.getQueryData(queryKeys.users) as UserListRetrieve[];

	const userAlreadyInOrganization = users?.some((user) => user.id === currentUserId);

	const toggleInviteUsersModal = useGlobalStore((state) => state.toggleInviteUsersModal);
	const userPermissions = useGlobalStore((state) => state.inviteUsersModal.userPermissions);
	const user = useGlobalStore((state) => state.inviteUsersModal.selectedUser);

	const permissionsList = Object.keys(permissions).filter((value) => value !== 'is_unit_member');
	const [usersList, setUsersList] = useState<UserListRetrieve[]>([]);
	const [selectedUserId, setSelectedUserId] = useState(user?.id);
	const [filter, setFilter] = useState('');
	const [filterTimeout, setFilterTimeout] = useState<any>();

	const { mutate: addUserToOrganization } = useCreateUserMembership();

	const filterHandler = (value: string) => {
		clearTimeout(filterTimeout);
		setFilter(value);
		setFilterTimeout(setTimeout(fetchUsersPage.bind(null, value), 500));
	};
	const fetchUsersPage = (filterTerm: string) => {
		if (filterTerm.length > 2)
			return UsersService.fetchUsersGlobal(filterTerm)
				.then((response) => {
					if (userAlreadyInOrganization)
						setUsersList(response.filter((user: UserListRetrieve) => user.id !== currentUserId));
					else setUsersList(response);
					Promise.resolve();
					return response;
				})
				.catch((error) => {
					return error;
				});
	};

	useEffect(() => {
		filterHandler(filter);
	}, [filter]);

	//for now is unit member is set to true so we can proceed with update, check this with ivan
	const formik = useFormik({
		initialValues: userPermissions ?? permissions,
		onSubmit: (data) => {
			if (!user)
				addUserToOrganization(
					{
						userId: String(selectedUserId),
						data: {
							organization: localStorage.getItem('currentOrganizationId') ?? '',
							permissions: data
						}
					},
					{
						onSuccess: () => {
							queryClient.invalidateQueries(queryKeys.users);
							toast.success(t('USER_ADDED_TO_ORG'));
							handleModalClose && handleModalClose();
						}
					}
				);
			else {
				const dataForUpdate = {
					organization: localStorage.getItem('currentOrganizationId'),
					permissions: { ...data, is_unit_member: true }
				};
				handleUpdate(selectedUserId, 'update', dataForUpdate).then(() => {
					toast.success(t('USER_PERMISSIONS_UPDATED'));
					handleModalClose && handleModalClose();
				});
			}
		},
		enableReinitialize: true
	});
	const handleCheckboxChange = (field: string, checked: boolean) => {
		switch (field) {
			case 'manage_organization':
			case 'manage_users':
				if (checked) formik.setFieldValue('view_users', true);

				formik.setFieldValue(field, checked);
				break;
			case 'validate_measurements':
				formik.setFieldValue(field, checked);
				if (checked) {
					formik.setFieldValue('create_measurements', true);
				}

				break;
			default:
				formik.setFieldValue(field, checked);
		}
	};

	const isCheckboxDisabled = (field: string) => {
		if (formik.values['validate_measurements' as keyof typeof permissions]) {
			if (field === 'create_measurements') return true;
		}

		if (
			formik.values['manage_organization' as keyof typeof permissions] ||
			formik.values['manage_users' as keyof typeof permissions]
		) {
			if (field === 'view_users') return true;
		} else return false;
	};

	return (
		<>
			<Modal
				closeModal={handleModalClose}
				open={open}
				modalTitle={user ? user.first_name + ' ' + user.last_name : t('ADD_USERS')}>
				<form
					className="w-full  overflow-y-auto "
					onSubmit={formik.handleSubmit}
					autoComplete="off">
					<div className={'px-6 pb-6'}>
						{!user && (
							<Autocomplete
								freeSolo
								disabled={user !== null}
								value={user}
								className={'mt-4 w-4/5 md:w-2/3 '}
								getOptionLabel={(option) => {
									if (typeof option === 'object' && option !== null && 'first_name' in option && 'last_name' in option && 'username' in option) {
										return `${option.first_name} ${option.last_name} - ${option.username}`;
									}
									return '';
								}}
								options={usersList}
								filterOptions={(e) => e}
								onInputChange={(event, newInputValue, reason) => {
									setFilter(newInputValue);
									if (reason === 'clear') {
										setSelectedUserId(undefined);
										setFilter('');
									}
								}}
								onChange={(e, value) => {
									if (value && typeof value !== 'string') setSelectedUserId(value.id);
								}}
								renderInput={(params) => <TextField {...params} label={t('FIND_USER')} />}
							/>
						)}

						<div className="flex-1 overflow-y-auto overflow-x-hidden">
							<List>
								<Box>
									<div>{t('PERMISSIONS')}</div>
									{permissionsList.map((value) => {
										return (
											<div key={value}>
												<Checkbox
													checked={formik.values[value as keyof typeof permissions]}
													onChange={(e) => {
														handleCheckboxChange(value, e.target.checked);
													}}
													name={value}
													disabled={!selectedUserId || isCheckboxDisabled(value)}
												/>

												{t(value.toUpperCase())}
											</div>
										);
									})}
								</Box>
							</List>
						</div>
						<div className={'mt-4 flex w-full justify-center gap-2 '}>
							<CancelButton
								onClick={() => {
									toggleInviteUsersModal({
										inviteUsersModalOpen: false,
										selectedUser: null,
										userPermissions: null
									});
								}}
								text={t('CANCEL')}
							/>
							<PrimaryButton
								type="submit"
								className={`${(user && !formik.dirty) || !selectedUserId ? 'hidden' : 'inherit'}`}
								text={t(user ? 'UPDATE' : 'ADD')}
							/>
						</div>
					</div>
				</form>
			</Modal>
		</>
	);
};
export default InviteUsersModal;
