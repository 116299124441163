import {
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent
} from '@mui/material';
import ConfigFineTune from 'components/CloudProcessing/StationConfigFineTune/ConfigFineTune';
import { useGlobalStore } from 'global-state/useStore';
import { getScalingCoeffForImg } from 'helper/helperFunctions';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MarkerOrUndefinedType } from './types';
import ImgMarker from './ImgMarker';
import './styles.scss';
import { useCalibrate } from 'rq/hooks/cloudProcessingHook';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { PROCESSING_STEPS } from 'pages/CloudProcessing/ProcessingSteps';
import Tooltip from '@mui/material/Tooltip';
import useStationDataFromStore from '../../../hooks/useStationDataFromStore';

const GCP = () => {
	const { t } = useTranslation();

	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const cloudProcessing = useGlobalStore((state) => state.cloudProcessing);
	const setCloudProcessingConfig = useGlobalStore((state) => state.setCloudProcessingConfig);
	const setCalibrationResults = useGlobalStore((state) => state.setCalibrationResults);
	const setCloudProcessingRotatedImage = useGlobalStore(
		(state) => state.setCloudProcessingRotatedImage
	);

	const { reprocessing, originalReprocessingData } = useGlobalStore(
		(state) => state.reprocessingMeasurement
	);

	const stationDataApplied = useGlobalStore((state) => state.stationChangesApplied);
	const setStationDataApplied = useGlobalStore((state) => state.setStationChangesApplied);

	/*	const { data: siteData } = useSite({ site_id: Number(siteId) });*/

	const selectedStation = useGlobalStore((state) => state.cloudProcessing.selectedStation);
	const { stationData } = useStationDataFromStore(selectedStation);

	const initialLoad = useRef(true);
	useEffect(() => {
		if (initialLoad.current) {
			initialLoad.current = false;
		} else {
			if (stationDataApplied) {
				stationDataApplied && !reprocessing && formik.handleSubmit();
			}
		}
	}, [stationData]);

	const { mutate: calibrate, isLoading: calibrationInProgress } = useCalibrate();

	const numberOfMarkers = stationData?.markers_coordinates?.length
		? Math.round(stationData?.markers_coordinates?.length / 3)
		: 4;

	useEffect(() => {
		if (numberOfMarkers !== formik.values.markers.length) formik.setFieldValue('markers', []);
		if (stationDataApplied) {
			setStationDataApplied(false);
		}
	}, [numberOfMarkers]);

	const [imgOrientation, setImgOrientation] = useState<'vertical' | 'horizontal'>(
		cloudProcessing.rotatedImage?.imgOrientation ?? 'vertical'
	);

	/*	const [imgDimensions, setImageDimensions] = useState({
		width: cloudProcessing.rotatedImage?.imgWidth,
		height: cloudProcessing.rotatedImage?.imgHeight
	});*/

	useEffect(() => {
		setImgOrientation(cloudProcessing.rotatedImage?.imgOrientation ?? 'vertical');
	}, [cloudProcessing.rotatedImage]);

	const imgRef = useRef<HTMLImageElement | null>(null);
	const imageDivContainerRef = useRef<HTMLDivElement>(null);

	const validationSchema = Yup.object().shape({
		cameraCalibrationNotNeeded: Yup.boolean(),
		x: Yup.string().when(['cameraCalibrationNotNeeded', 'is_looking_down'], {
			is: (cameraCalibrationNotNeeded: boolean, is_looking_down: boolean) =>
				is_looking_down ? false : !cameraCalibrationNotNeeded,
			then: Yup.string().required(),
			otherwise: Yup.string().nullable()
		}),
		y: Yup.string().when(['cameraCalibrationNotNeeded', 'is_looking_down'], {
			is: (cameraCalibrationNotNeeded: boolean, is_looking_down: boolean) =>
				is_looking_down ? false : !cameraCalibrationNotNeeded,
			then: Yup.string().required(),
			otherwise: Yup.string().nullable()
		}),
		z: Yup.string().when(['cameraCalibrationNotNeeded', 'is_looking_down'], {
			is: (cameraCalibrationNotNeeded: boolean, is_looking_down: boolean) =>
				is_looking_down ? false : !cameraCalibrationNotNeeded,
			then: Yup.string().required(),
			otherwise: Yup.string().nullable()
		}),
		markers: Yup.array()
			.length(Number(numberOfMarkers))
			.of(
				Yup.object().shape({ id: Yup.number(), clientImgX: Yup.number(), clientImgY: Yup.number() })
			)
	});

	const formik = useFormik({
		initialValues: {
			x: reprocessing ? originalReprocessingData?.measurement_orginal.device_orientation[0] : '',
			y: reprocessing ? originalReprocessingData?.measurement_orginal.device_orientation[1] : '',
			z: reprocessing ? originalReprocessingData?.measurement_orginal.device_orientation[2] : '',
			cameraCalibrationNotNeeded: numberOfMarkers > 4,
			degreesOrRadians: 'radians' as 'radians' | 'degrees',
			markers: Array(numberOfMarkers).fill(undefined) as MarkerOrUndefinedType[],
			is_looking_down: false,
			cameraData: 'cameraOrientation' as 'cameraOrientation' | 'cameraPosition'
		},
		validateOnMount: false,
		validationSchema: validationSchema,
		enableReinitialize: true,
		onSubmit: (data) => {
			//Method not reading changes from store in time so saved the state in separate variable

			let currentStepOnSubmit = currentStep;
			if (currentStep.stepNumber > displayStep.stepNumber) {
				setCurrentStep(displayStep);
				currentStepOnSubmit = displayStep;
			}
			const convertToRadiansIfDegrees = data.degreesOrRadians === 'degrees' ? Math.PI / 180 : 1;
			const cameraOrientation =
				data.cameraData === 'cameraOrientation'
					? [
							Number(data.x) * convertToRadiansIfDegrees,
							Number(data.y) * convertToRadiansIfDegrees,
							Number(data.z) * convertToRadiansIfDegrees
					  ]
					: [];

			const cameraPosition =
				data.cameraData === 'cameraPosition'
					? [Number(data.x), Number(data.y), Number(data.z)]
					: [];

			calibrate(
				{
					config: cloudProcessing.config,
					processing_id: `${cloudProcessing.video?.processing_id}`,
					gcps_image: scaledMarkerCoords.reduce((acc, currValue, currentIndex) => {
						acc[currentIndex] = currValue.y * Number(cloudProcessing.rotatedImage?.scaledY);
						acc[currentIndex + data.markers.length] =
							currValue.x * Number(cloudProcessing.rotatedImage?.scaledX);
						return acc;
					}, [] as number[]),
					camera_orientation: cameraOrientation,
					camera_position: cameraPosition,
					gcps_site: stationData?.markers_coordinates ?? [],
					profile: stationData?.profile?.['free_params'] ?? [],
					roughness: Number(stationData?.profile.roughness),
					rotation_value: Number(cloudProcessing.rotatedImage?.rotation),
					is_looking_down: data.is_looking_down
				},
				{
					onSuccess: (onSuccessData) => {
						setCloudProcessingConfig(onSuccessData.updated_config);
						setCalibrationResults(onSuccessData.calibration_results);
						const nextStep = PROCESSING_STEPS[currentStepOnSubmit.stepNumber + 1];
						setStationDataApplied(true);
						nextStep && setDisplayStep(nextStep);
						nextStep && setCurrentStep(nextStep);
					}
				}
			);
		}
	});
	/*	useEffect(() => {
		reprocessing && prepareMarkers();
	}, [imgRef.current]);*/

	const prepareMarkers = () => {
		if (!imgRef.current) return;

		const { imgScaleX, imgScaleY } = getScalingCoeffForImg(
			`${cloudProcessing.rotatedImage?.imgSrc}`,
			imgRef
		);

		let markerCoordinates: any = [];
		const data = originalReprocessingData?.measurement_orginal.markers_coordinates;
		if (data && data?.length > 0) {
			const thirdLength = data.length / 3;
			const yValues = data.slice(0, thirdLength);
			const xValues = data.slice(thirdLength, 2 * thirdLength);
			markerCoordinates = xValues.map((x, index) => {
				return {
					id: index + 1,
					clientImgX: x / imgScaleX,
					clientImgY: yValues[index] / imgScaleY
				};
			});

			formik.setFieldValue('markers', markerCoordinates);
		}
	};

	const getClickCoordinatesAndAddMarker = (
		event: React.MouseEvent<HTMLImageElement, MouseEvent>
	) => {
		if (!imgRef.current) return;

		const imgPosition = imgRef.current.getBoundingClientRect();
		const imageClickCoordinates = {
			clientImgX: event.clientX - imgPosition.x,
			clientImgY: event.clientY - imgPosition.y
		};

		const newFormikValue = [...formik.values.markers];
		const undefinedCount = numberOfMarkers - newFormikValue.length;

		for (let i = 0; i < undefinedCount; i++) {
			newFormikValue.push(undefined);
		}

		const indexOfFirstUndefined = newFormikValue.findIndex((marker) => marker === undefined);
		if (indexOfFirstUndefined === -1) return;
		newFormikValue[indexOfFirstUndefined] = {
			id: indexOfFirstUndefined + 1,
			clientImgX: imageClickCoordinates.clientImgX,
			clientImgY: imageClickCoordinates.clientImgY
		};

		formik.setFieldValue('markers', newFormikValue);
	};

	const removeMarker = (markerId: number) => {
		const newFormikValue = [...formik.values.markers];
		const markerIndex = newFormikValue.findIndex((marker) => marker?.id === markerId);
		newFormikValue[markerIndex] = undefined;
		formik.setFieldValue('markers', newFormikValue);
	};

	const changeMarkerCoords = (markerId: number, clientImgX: number, clientImgY: number) => {
		const newMarkersArray = [...formik.values.markers];
		const index = newMarkersArray.findIndex((marker) => marker && marker.id === markerId);
		newMarkersArray[index] = {
			id: markerId,
			clientImgX: clientImgX,
			clientImgY: clientImgY
		};
		formik.setFieldValue('markers', newMarkersArray);
	};

	const handleCalibrationChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		type: 'x' | 'y' | 'z'
	) => {
		if (
			Number(event.target.value) ||
			Number(event.target.value) === 0 ||
			event.target.value === ''
		) {
			formik.setFieldValue(`${type}`, event.target.value);
		}
	};

	const selectVideoCalibrationType = (event: SelectChangeEvent) => {
		formik.setFieldValue('cameraData', event.target.value);
	};

	const scaledMarkerCoords = useMemo(() => {
		const scaledMarkers: {
			id: number;
			x: number;
			y: number;
			clientImgX: number;
			clientImgY: number;
		}[] = [];

		formik.values.markers?.forEach((marker) => {
			if (!marker) return;
			scaledMarkers.push({
				...marker,
				x: marker.clientImgX,
				y: marker.clientImgY
			});
		});
		return scaledMarkers;
	}, [formik.values.markers]);

	return (
		<div className="text-lg ">
			<form onSubmit={formik.handleSubmit} autoComplete="off" className={'w-full'}>
				<div className="flex flex-col">
					<div className="flex flex-row flex-wrap">
						<div className={'mr-8 overflow-auto'}>
							<div className=" mb-2 text-base font-bold">
								{t('CLICK_ON_IMAGE_TO_ADD_MARKERS')} (
								{numberOfMarkers > 1
									? t('MARKERS_NEEDED', { markers_amount: numberOfMarkers })
									: `${numberOfMarkers} ${t('MARKER_NEEDED')}`}
								)
							</div>
							<div
								className={classNames(
									{ 'overflow-x-auto overflow-y-auto': true },
									{
										'w-[25rem]': imgOrientation === 'vertical'
									},
									{
										'w-[50rem]': imgOrientation === 'horizontal'
									}
								)}>
								<div className="relative inline-block " ref={imageDivContainerRef}>
									<img
										ref={(node) => {
											imgRef.current = node;
										}}
										draggable={false}
										className={classNames(
											{ 'rotated-first-frame cursor-pointer select-none': true },
											{
												'w-[25rem]': imgOrientation === 'vertical'
											},
											{
												'w-[50rem]': imgOrientation === 'horizontal'
											}
										)}
										alt={t('VIDEO_FIRST_FRAME')}
										onLoad={() => {
											reprocessing && prepareMarkers();
											const { imgScaleX, imgScaleY } = getScalingCoeffForImg(
												`${cloudProcessing.rotatedImage?.imgSrc}`,
												imgRef
											);
											setCloudProcessingRotatedImage({
												...cloudProcessing.rotatedImage,
												imgSrc: `${cloudProcessing.rotatedImage?.imgSrc}`,
												scaledX: imgScaleX,
												scaledY: imgScaleY
											});
											reprocessing && prepareMarkers();
										}}
										onClick={(event) => getClickCoordinatesAndAddMarker(event)}
										src={`${cloudProcessing.rotatedImage?.imgSrc}?${cloudProcessing.rotatedImage?.rotation}`}
									/>

									{scaledMarkerCoords.map((marker) => {
										if (marker === undefined) return;
										return (
											<ImgMarker
												imgRef={imgRef}
												imageDivContainerRef={imageDivContainerRef}
												changeMarkerCoords={changeMarkerCoords}
												marker={marker}
												key={marker.id}
												removeMarker={removeMarker}
											/>
										);
									})}
								</div>
							</div>
						</div>
						<div className={'flex-auto'}>
							<div className="flex h-full flex-col gap-4">
								<div className={'grow'}>
									<Select
										size="small"
										className="text-lg font-bold text-black text-opacity-100"
										autoWidth
										value={formik.values.cameraData}
										onChange={selectVideoCalibrationType}>
										<MenuItem value={'cameraOrientation'}>{t('CAMERA_ORIENTATION')}</MenuItem>
										<MenuItem value={'cameraPosition'}>{t('CAMERA_POSITION')}</MenuItem>
									</Select>
									<div className="mt-4 flex flex-row text-black">
										<div>
											{numberOfMarkers <= 4 ? 'x*: ' : 'x:'}
											<input
												className={classNames(
													{
														'ml-1 mr-4 w-24 rounded-md border border-black px-2 py-1 disabled:bg-gray-300':
															true
													},
													{
														'border-red-500':
															formik.touched.x && formik.errors.x && !formik.values.x
													}
												)}
												type="string"
												name="x"
												disabled={formik.values.is_looking_down}
												onBlur={formik.handleBlur}
												value={formik.values.x}
												onChange={(event) => handleCalibrationChange(event, 'x')}
											/>
										</div>
										<div>
											{numberOfMarkers <= 4 ? 'y*: ' : 'y:'}
											<input
												className={classNames(
													{
														'ml-1 mr-4 w-24 rounded-md border border-black px-2 py-1 disabled:bg-gray-300':
															true
													},
													{
														'border-red-500':
															formik.touched.y && formik.errors.y && !formik.values.y
													}
												)}
												type="string"
												name="y"
												disabled={formik.values.is_looking_down}
												onBlur={formik.handleBlur}
												value={formik.values.y}
												onChange={(event) => handleCalibrationChange(event, 'y')}
											/>
										</div>
										<div>
											{numberOfMarkers <= 4 ? 'z*: ' : 'z:'}
											<input
												className={classNames(
													{
														'ml-1 mr-4 w-24 rounded-md border border-black px-2 py-1 disabled:bg-gray-300':
															true
													},
													{
														'border-red-500':
															formik.touched.z && formik.errors.z && !formik.values.z
													}
												)}
												type="string"
												name="z"
												disabled={formik.values.is_looking_down}
												onBlur={formik.handleBlur}
												value={formik.values.z}
												onChange={(event) => handleCalibrationChange(event, 'z')}
											/>
										</div>{' '}
										{numberOfMarkers > 4 && (
											<span className={'flex items-center text-sm text-red-500'}>
												*{t('OPTIONAL')}
											</span>
										)}
									</div>
									<div className="h-[2.625rem]">
										{formik.values.cameraData === 'cameraOrientation' && (
											<RadioGroup
												name="degreesOrRadians"
												onChange={formik.handleChange}
												className="flex flex-row items-center  text-black"
												value={formik.values.degreesOrRadians}>
												<FormControlLabel
													value="radians"
													control={<Radio />}
													label={t('RADIANS')}
												/>
												<FormControlLabel
													value="degrees"
													control={<Radio />}
													label={t('DEGREES')}
												/>
											</RadioGroup>
										)}
									</div>
									<div className={'text-black'}>
										<FormControlLabel
											control={
												<Checkbox
													checked={formik.values.is_looking_down}
													name={'is_looking_down'}
													onChange={formik.handleChange}
												/>
											}
											label={t('IS_LOOKING_DOWN')}
										/>
									</div>
									<div className="mt-2 flex flex-row">
										<Button
											className="mr-4 normal-case"
											variant="contained"
											color="error"
											onClick={() => {
												setDisplayStep(PROCESSING_STEPS[displayStep.stepNumber - 1] ?? displayStep);
											}}>
											{t('BACK')}
										</Button>
										{!calibrationInProgress ? (
											<Tooltip
												title={
													formik.values.markers.includes(undefined)
														? t('MARKERS_NEEDED', { markers_amount: numberOfMarkers })
														: ''
												}>
												<span>
													<Button
														className="ml-4 normal-case"
														variant="contained"
														color="info"
														type="submit"
														disabled={formik.values.markers.includes(undefined) || !formik.isValid}>
														{t('NEXT')}
													</Button>
												</span>
											</Tooltip>
										) : (
											<div className="ml-4 flex w-14 flex-row items-center justify-center">
												<CircularProgress className="h-6 w-6" />
											</div>
										)}
									</div>
								</div>
								<div className={'max-w-[40rem] self-start'}>
									<ConfigFineTune></ConfigFineTune>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default GCP;
