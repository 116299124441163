import { InteractionProps } from '@microlink/react-json-view';
import { IAvailablePermissions } from 'components/types';
import { JsonData } from 'helper/types';
import i18n from 'i18next';
import { utcToZonedTime } from 'date-fns-tz';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import { SiteAlarmRetrieve } from 'interfaces/models/SiteAlarmRetrieve';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import { Dispatch, RefObject, SetStateAction } from 'react';
import toast from 'react-hot-toast';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import { convertDataToVectors, drawVectors, drawWaterLine } from 'utils/canvasFunctions';

export const checkPermissions = (action: IAvailablePermissions | IAvailablePermissions[]) => {
	const user = queryClient.getQueryData(queryKeys.me) as UserListRetrieve;
	const organizationId = window.localStorage.getItem('currentOrganizationId');
	const currentOrg = user?.organizations?.find((org) => org.id === Number(organizationId));

	if (user.super_admin) {
		return true;
	} else {
		let hasPermission = true;
		if (user && currentOrg) {
			if (typeof action === 'object') {
				action.forEach((act) => {
					if (currentOrg.permissions && !currentOrg.permissions[act]) hasPermission = false;
				});
			} else if (currentOrg.permissions && !currentOrg.permissions[action]) hasPermission = false;
		}
		return hasPermission;
	}
};

export const displayUserFullName = (user: UserListRetrieve) => {
	if (user.first_name?.trim() === '' && user.last_name?.trim() === '') {
		return user.username;
	} else {
		return user.first_name + ' ' + user.last_name;
	}
};

export const displayBreadCrumbText = (pathname: string, user: UserListRetrieve) => {
	if (pathname === '/') return 'Discharge';
	else if (pathname.includes('users/')) {
		if (user.first_name?.trim() || user.last_name?.trim())
			return user.first_name + ' ' + user.last_name;
		else return user.username;
	} else return null;
};

export const generateAlarmDescription = ({
	alarmType,
	alarmInterval,
	alarmValue,
	alarmVariable
}: {
	alarmType: SiteAlarmRetrieve.alarm_type;
	alarmInterval: number | undefined;
	alarmValue: number | undefined;
	alarmVariable: string | undefined;
}) => {
	if (alarmType === 'NO_MEAS_ARRIVING')
		return `${i18n.t('NO_MEAS_ARRIVING')} in last ${alarmInterval} ${
			alarmInterval && alarmInterval > 1
				? i18n.t('MINUTES').toLowerCase()
				: i18n.t('MINUTE').toLowerCase()
		}`;
	else if (alarmType === 'HIGHER_THAN' || alarmType === 'LOWER_THAN') {
		const suffix =
			alarmVariable === 'LEVEL'
				? 'm'
				: alarmVariable === 'DISCHARGE'
				? 'm3/s'
				: alarmVariable === 'VELOCITY'
				? 'm/s'
				: '';
		return `${i18n.t(alarmVariable ?? '')} ${i18n.t(alarmType).toLowerCase()} ${
			alarmValue ?? ''
		} ${suffix}`;
	}
};

export const checkAlarmSeverity = (alarms: SiteAlarmRetrieve[], siteId: number) => {
	let alarmSeverity = '';
	alarms.forEach((alarm) => {
		if (alarm.site === siteId && alarm.state === 'TRIGGERED') {
			if (alarm.severity === 'HIGH') alarmSeverity = 'HIGH';
			else if (alarm.severity === 'MEDIUM' && alarmSeverity !== 'HIGH') alarmSeverity = 'MEDIUM';
			else if (alarm.severity === 'LOW' && alarmSeverity !== 'HIGH' && alarmSeverity !== 'MEDIUM')
				alarmSeverity = 'LOW';
		}
	});
	return alarmSeverity;
};

export const capitalizeFirstLetter = (text: string) => {
	return text.charAt(0).toUpperCase() + text.slice(1);
};

export const convertToUsersTimezone = (
	date: Date | string,
	site: { timezone_offset?: string },
	timezoneDisplay: UserListRetrieve.timezone_display | null | undefined
) => {
	const timeZone = timezoneDisplay || 'LOCAL';
	switch (timeZone.toUpperCase()) {
		case 'LOCAL':
			return utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
		case 'UTC':
			return utcToZonedTime(date, 'UTC');
		default: {
			return new Date(date.toString().replace(/(\+\d{2}:\d{2})$/, ''));
		}
	}
};

/*Advanced configuration validation helper methods*/

export const parseAdvancedConfigSchema = (schemaStr: string): string[] => {
	return schemaStr.split('|').map((type) => type.trim().toLowerCase());
};

export const isArrayOfType = (arr: any[], typeChecker: (val: any) => boolean): boolean => {
	return Array.isArray(arr) && arr.every(typeChecker);
};

export const validateAdvancedConfigItemType = (value: any, types: string[]): boolean => {
	return types.some((type) => {
		switch (type) {
			case 'str':
				return typeof value === 'string';
			case 'bool':
				return typeof value === 'boolean';
			case 'int':
				return Number.isInteger(value);
			case 'float':
				return typeof value === 'number' && !Number.isInteger(value);
			case 'string_array':
				return isArrayOfType(value, (value) => typeof value === 'string');
			case 'float_array':
				return isArrayOfType(
					value,
					(value) => typeof value === 'number' && !Number.isInteger(value)
				);
			case 'int_array':
				return isArrayOfType(
					value,
					(value) => typeof value === 'number' && Number.isInteger(value)
				);
			case 'list[str]':
				return isArrayOfType(value, (value) => typeof value === 'string');
			case 'none':
				return value === null;
			default:
				return false;
		}
	});
};

export const validateAdvancedConfigArrayItemType = (value: any, types: string[]): boolean => {
	return types.some((type) => {
		switch (type) {
			case 'string_array':
				return typeof value === 'string';
			case 'float_array':
				return typeof value === 'number' && !Number.isInteger(value);
			case 'int_array':
				return typeof value === 'number' && Number.isInteger(value);

			case 'list[str]':
				return typeof value === 'string';
			case 'none':
				return value === null;
			default:
				return false;
		}
	});
};

export const validateAdvancedConfigEdit = (
	edit: InteractionProps,
	validationSchema: JsonData | null
) => {
	const { name, new_value, namespace } = edit;
	const schemaPath = [...namespace, name];
	let currentSchema: string | JsonData = validationSchema ?? {};

	for (const key of schemaPath) {
		if (typeof currentSchema === 'string') {
			break;
		}
		currentSchema = currentSchema && (currentSchema[key as string] as JsonData);
	}

	if (typeof currentSchema === 'string') {
		const allowedTypes = parseAdvancedConfigSchema(currentSchema);
		/*If parsed name is not a number that means that it is string so we regular type validation,
		else if parsed name is a number that means that array is being edited so we need to parse value of child based on parent schema
		for e.g. if schema is float_array we need to check if element in array which is edited is of type float
		*/
		if (isNaN(Number(name))) {
			if (!validateAdvancedConfigItemType(new_value, allowedTypes)) {
				toast.error(i18n.t('INVALID_PROP_TYPE', { currentSchema }));
				return false;
			}
		} else {
			if (!validateAdvancedConfigArrayItemType(new_value, allowedTypes)) {
				toast.error(i18n.t('INVALID_PROP_TYPE', { currentSchema }));
				return false;
			}

			return true;
		}
	}
	return true;
};

export const validateAdvancedConfigAdd = (add: InteractionProps) => {
	const { name, existing_value } = add;
	return Array.isArray(existing_value) && name !== 'advanced_config_map';
};

export const validateAdvancedConfigDelete = (add: InteractionProps) => {
	const { name, existing_src, namespace } = add;
	return (
		(namespace[namespace.length - 1] === 'cameras' && (existing_src as any).cameras_count > 1) ||
		(!isNaN(Number(name)) && name !== 'advanced_config_map')
	);
};

export const onAdvancedConfigItemDelete = (
	del: InteractionProps,
	stateHandler: Dispatch<SetStateAction<JsonData | null>>
) => {
	if (((del.existing_src as JsonData).cameras_count as number) > 1) {
		(del.updated_src as JsonData).cameras_count = (del.existing_src as any).cameras_count - 1;
		del.name && (del.updated_src as any).advanced_config_map.splice(Number(del.name), 1);

		stateHandler(del.updated_src as JsonData);
	} else {
		return false;
	}
};

export const onAdvancedConfigItemAdd = (
	add: InteractionProps,
	fieldsData: any,
	stateHandler: Dispatch<SetStateAction<JsonData | null>>
) => {
	if (Array.isArray(add.existing_value)) {
		if (add.name === 'cameras') {
			(add.updated_src as any).advanced_config_map[0].cameras = [
				...add.existing_value,
				fieldsData?.advanced_config_map[0].cameras[0]
			];
			if (fieldsData?.advanced_config_map[1] !== null)
				(add.updated_src as any).advanced_config_map = [
					...(add.updated_src as any).advanced_config_map,
					fieldsData?.advanced_config_map[1]
				];

			(add.updated_src as any).cameras_count = (add.updated_src as any).cameras_count + 1;
		}
		stateHandler(add.updated_src as JsonData);
	} else {
		stateHandler(add.existing_src as JsonData);
		return false;
	}
};

export const checkOrgIdAndPermissions = (user: UserListRetrieve, userId: number | undefined) => {
	let organizationId =
		localStorage.getItem(`lastOrganization-${user.id}`) ??
		(user.organizations && user.organizations.length !== 0
			? user.organizations.some((org) => org.id === 1)
				? 1
				: user.organizations[0].id
			: null);

	if (
		user.organizations &&
		user.organizations.length !== 0 &&
		!user.organizations.some((org: OrganizationListRetrieve) => org.id === Number(organizationId))
	) {
		organizationId = user.organizations[0].id;
	}

	if (user.organizations && user.organizations.length === 0) {
		localStorage.removeItem(`lastOrganization-${user.id}`);
		localStorage.removeItem('currentOrganizationId');
		organizationId = null;
	}

	if (organizationId) {
		localStorage.setItem('currentOrganizationId', `${organizationId}`);
		localStorage.setItem(`lastOrganization-${userId}`, `${organizationId}`);
	}

	const organization = user.organizations?.find(
		(org: OrganizationListRetrieve) => org.id === Number(organizationId)
	);

	const permissions: MembershipPermissionCreateUpdate | null = organization?.permissions ?? null;
	const name: string | null = organization?.name ?? null;

	return { id: Number(organizationId), permissions: permissions, name: name };
};

export const checkOrgPermissionsForUser = (user: UserListRetrieve | null, orgId: number) => {
	const id = orgId;
	const organization = user?.organizations?.find(
		(org: OrganizationListRetrieve) => org.id === orgId
	);
	const permissions = organization?.permissions ?? null;
	const name = organization?.name ?? null;
	return { id: id, permissions: permissions, name: name };
};

export const getScalingCoeffForImg = (
	originalImgSrc: string,
	scaledImageRef: RefObject<HTMLImageElement>
) => {
	const imgElement = scaledImageRef.current;
	if (!imgElement) {
		return { imgScaleX: 1, imgScaleY: 1 };
	}

	const computedStyle = window.getComputedStyle(imgElement);
	const imgWidth = parseFloat(computedStyle.width);
	const imgHeight = parseFloat(computedStyle.height);

	const imgScaleX = imgElement.naturalWidth / imgWidth;
	const imgScaleY = imgElement.naturalHeight / imgHeight;

	return { imgScaleX, imgScaleY };
};

export const handleCanvasDrawing = (
	canvasRef: RefObject<HTMLCanvasElement>,
	imgRef: RefObject<HTMLImageElement>,
	processingResults: any,
	cloudProcessing: any
) => {
	const ctx = canvasRef.current?.getContext('2d');
	if (!ctx) return;

	ctx.canvas.width = imgRef.current?.width ?? 0;
	ctx.canvas.height = imgRef.current?.height ?? 0;
	const imgScaleX = cloudProcessing.rotatedImage.scaledX;

	const imgScaleY = cloudProcessing.rotatedImage.scaledY;
	//clear if redrawing a result
	imgRef.current?.width &&
		imgRef.current?.height &&
		ctx.clearRect(0, 0, imgRef.current?.width, imgRef.current?.height);

	const waterLine = processingResults.level_geometry;
	!!waterLine && drawWaterLine(waterLine, ctx, imgScaleX, imgScaleY);
	const vectorData =
		!!processingResults.image_displacement_field &&
		convertDataToVectors(processingResults.image_displacement_field);
	!!vectorData && drawVectors(vectorData, ctx, imgScaleX, imgScaleY);
};

export const getImageOrientation = (image: HTMLImageElement) => {
	let orientation: 'horizontal' | 'vertical' | undefined;

	if (image.width > image.height) {
		orientation = 'horizontal';
	} else orientation = 'vertical';

	return orientation;
};

export const isoDateWithoutTimeZone = (date: Date | null | string) => {
	if (date === null || date == 'Invalid Date') return date;
	const timestamp = new Date(date).getTime() - new Date(date).getTimezoneOffset() * 60000;
	const correctDate = new Date(timestamp);
	return correctDate.toISOString();
};

export const isEqual: any = (a: any, b: any) => {
	if (a === null || a === undefined || b === null || b === undefined) {
		return a === b;
	}

	if (typeof a !== typeof b) {
		return false;
	}

	if (typeof a === 'string' || typeof a === 'number' || typeof a === 'boolean') {
		return a === b;
	}

	if (Array.isArray(a) && Array.isArray(b)) {
		if (a.length !== b.length) {
			return false;
		}

		return a.every((item, index) => isEqual(item, b[index]));
	}

	if (typeof a === 'object' && typeof b === 'object') {
		const keysA = Object.keys(a);
		const keysB = Object.keys(b);

		if (keysA.length !== keysB.length) {
			return false;
		}

		return keysA.every((key) => isEqual(a[key], b[key]));
	}

	return false;
};
