import { apiClient } from 'agent/apiClient';
import { ICloudProcessingService } from './types';

const CloudProcessingService: ICloudProcessingService = {
	uploadVideo: async (data) => {
		const formData = new FormData();
		formData.append('video', data.video);
		const config = {
			headers: {
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'video/*',
				Authorization: 'Bearer ' + localStorage.getItem('access_token')
			}
		};
		return await apiClient
			.post(`cloudprocessing/${data.siteId}/stations/${data.stationId}/video/`, formData, config)
			.then((response) => response.data);
	},

	getBaseConfig: async () => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};
		return await apiClient
			.get(`cloudprocessing/emptyconfig/`, config)
			.then((response) => response.data);
	},

	rotateImage: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};

		return await apiClient
			.post(`cloudprocessing/${data.processing_id}/rotateimg/`, data.body, config)
			.then((response) => response.data);
	},

	calibrate: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};
		return await apiClient
			.post(`cloudprocessing/calibration/`, data, config)
			.then((response) => response.data);
	},

	getWaterLine: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};
		return await apiClient
			.post(`cloudprocessing/levelwaterline/`, data, config)
			.then((res) => res.data);
	},

	getWaterLineFromWaterCol: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};
		return await apiClient
			.post(`cloudprocessing/watercolumncalculation/`, data, config)
			.then((response) => response.data);
	},

	processVideo: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};
		return await apiClient
			.post(`cloudprocessing/${data.processing_id}/parameters/`, data.body, config)
			.then((res) => res.data);
	},

	getIsProcessingDone: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? '',
				'Content-Type': 'application/json'
			}
		};

		return await apiClient
			.get(`cloudprocessing/status/${data.processingId}/`, config)
			.then((response) => response.data);
	},

	getResults: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? ''
			}
		};

		return await apiClient
			.get(`cloudprocessing/results/get/${data.processing_id}/`, config)
			.then((response) => response.data);
	},

	confirmAndSaveResults: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? ''
			}
		};

		return await apiClient
			.put(`cloudprocessing/results/${data.processing_id}/confirmation/`, {}, config)
			.then((response) => response.data);
	},

	startDisplacementProcessing: async (data) => {
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? ''
			}
		};

		return await apiClient
			.post(`cloudprocessing/${data.processing_id}/displacement/`, data.body, config)
			.then((response) => response.data);
	},

	reprocessMeasurement: async (data) => {
		const { site_id, station_id, measurement_id } = data;
		const config = {
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem('access_token'),
				Organization: localStorage.getItem('currentOrganizationId') ?? ''
			}
		};

		return await apiClient
			.get(
				`sites/${site_id}/stations/${station_id}/measurements/${measurement_id}/reprocess/`,
				config
			)
			.then((res) => {
				return res.data;
			});
	}
};

export default CloudProcessingService;
