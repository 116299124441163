import { CircularProgress } from '@mui/material';
import { useGlobalStore } from 'global-state/useStore';
import CloudProcessingStepper from 'pages/CloudProcessing/components/CloudProcessingStepper';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import WaterLevel from 'components/CloudProcessing/Column/WaterLevel';
import GCP from 'components/CloudProcessing/GCP/GCP';
import Results from 'components/CloudProcessing/Results/Results';
import UploadVideo from 'components/CloudProcessing/UploadVideo/UploadVideo';
import { useSite } from 'rq/hooks/sitesHook';
import CloudProcessingService from 'services/CloudProcessingService/CloudProcessingService';
import { PROCESSING_STEPS, STEPS } from './ProcessingSteps';

const CloudProcessing = () => {
	const { t } = useTranslation();

	const displayStep = useGlobalStore((state) => state.displayStep);
	const currentStep = useGlobalStore((state) => state.currentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const displacementProcessing = useGlobalStore((state) => state.displacementProcessing);
	const setDisplacementProcessing = useGlobalStore((state) => state.setDisplacementProcessing);
	const setVideoBeingProcessed = useGlobalStore((state) => state.setVideoBeingProcessed);
	const setCloudProcessingRotatedImage = useGlobalStore(
		(state) => state.setCloudProcessingRotatedImage
	);
	const removeUploadedCloudProcessingConfig = useGlobalStore(
		(state) => state.removeUploadedCloudProcessingConfig
	);
	const toggleReprocessingMeasurement = useGlobalStore(
		(state) => state.toggleReprocessingMeasurement
	);
	const reprocessingMeasurement = useGlobalStore((state) => state.reprocessingMeasurement);
	const setCloudProcessingVideo = useGlobalStore((state) => state.setCloudProcessingVideo);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);
	const setDKStationData = useGlobalStore((state) => state.setDischargeKeeperStationData);
	const setDischargeStationData = useGlobalStore((state) => state.setDischargeStationData);

	const { siteId, stationId, measurementId } = useParams();
	const initialMount = useRef<boolean>(true);
	const processingStepsTabsRef = useRef<HTMLDivElement>(null);

	const [reprocessingDataLoading, setReprocessingDataLoading] = useState(false);

	const { data: siteData, isLoading: loading } = useSite({
		site_id: Number(siteId),
		queryParams: {
			sub: false
		}
	});

	useEffect(() => {
		setSelectedSiteId(Number(siteId));
	}, [siteId]);

	useEffect(() => {
		if (reprocessingMeasurement.reprocessing || measurementId) {
			setReprocessingDataLoading(true);
			CloudProcessingService.reprocessMeasurement({
				site_id: Number(siteId),
				measurement_id: Number(measurementId),
				station_id: Number(stationId)
			}).then((res) => {
				setCloudProcessingVideo({
					processing_id: res.processing_id,
					video: res.video,
					first_frame: res.first_frame
				});
				toggleReprocessingMeasurement({
					reprocessing: true,
					originalReprocessingData: res
				});

				setReprocessingDataLoading(false);
			});
		}
	}, [reprocessingMeasurement.reprocessing, measurementId]);

	useEffect(() => {
		if (initialMount.current) {
			initialMount.current = false;
			return;
		}
		if (!processingStepsTabsRef.current) return;
		const processingTabsBoundingClientRect = processingStepsTabsRef.current.getBoundingClientRect();
		if (processingTabsBoundingClientRect.top < 0) {
			window.scrollBy({ top: processingTabsBoundingClientRect.top, behavior: 'smooth' });
		}
	}, [displayStep]);

	useEffect(() => {
		return () => {
			setCurrentStep(PROCESSING_STEPS[0]);
			setDisplayStep(PROCESSING_STEPS[0]);
			setDisplacementProcessing(false);
			setVideoBeingProcessed(false);
			setCloudProcessingRotatedImage({ imgSrc: '' });
			removeUploadedCloudProcessingConfig(null);
			setDKStationData(null);
			setDischargeStationData(null);
		};
	}, []);

	if (loading) return <div></div>;

	if (
		!displacementProcessing &&
		(siteData?.profile.type !== 'FREE' ||
			!siteData.markers_coordinates ||
			siteData.markers_coordinates?.length === 0 ||
			!siteData?.profile?.['free_params'] ||
			siteData?.profile?.['free_params'].length === 0)
	) {
		return (
			<div className="mt-4 text-xl ">
				{t('SITE_MISSING_INFO')}:{' '}
				<div className={'ml-12 flex flex-col py-8 font-bold text-red-500'}>
					{(!siteData?.markers_coordinates || siteData.markers_coordinates?.length === 0) && (
						<span>- {t('MARKER_COORDINATES_MISSING_MESSAGE')}</span>
					)}
					{siteData?.profile.type !== 'FREE' && <span>- {t('WRONG_SITE_PROFILE_MESSAGE')}</span>}

					{(!siteData?.profile?.['free_params'] ||
						siteData?.profile?.['free_params'].length === 0) && (
						<span>- {t('SITE_NOT_PROPERLY_DEFINED_MESSAGE')}</span>
					)}
				</div>
				<span>{t('BUT_YOU_CAN_STILL_DO') + ' '}</span>
				<span
					className="cursor-pointer font-bold underline"
					onClick={setDisplacementProcessing.bind(this, true)}>
					{t('DISPLACEMENT_PROCESSING').toLowerCase()}
				</span>{' '}
				<span>{t('OR_YOU_CAN') + ' '}</span>
				<span className="lowercase">{t('CHANGE_SITE_INFO')}.</span>
			</div>
		);
	}

	if (reprocessingDataLoading)
		return (
			<div className="ml-4 flex h-full  w-full flex-row items-center justify-center">
				<CircularProgress size={75} thickness={5} />
			</div>
		);

	return (
		<div className={'flex flex-row gap-4'}>
			<CloudProcessingStepper />

			<div ref={processingStepsTabsRef} className="w-full">
				<div className={displayStep.name === STEPS.UPLOAD ? 'block' : 'hidden'}>
					<UploadVideo />
				</div>

				{!displacementProcessing &&
					currentStep.stepNumber >=
						Number(PROCESSING_STEPS.find((step) => step.name === STEPS.GCP)?.stepNumber) && (
						<div className={displayStep.name === STEPS.GCP ? 'block' : 'hidden'}>
							<GCP />
						</div>
					)}
				{!displacementProcessing &&
					currentStep.stepNumber >=
						Number(
							PROCESSING_STEPS.find((step) => step.name === STEPS.WATER_LEVEL)?.stepNumber
						) && (
						<div className={displayStep.name === STEPS.WATER_LEVEL ? 'block' : 'hidden'}>
							<WaterLevel />
						</div>
					)}

				{currentStep.stepNumber >=
					Number(PROCESSING_STEPS.find((step) => step.name === STEPS.RESULTS)?.stepNumber) && (
					<div className={displayStep.name === STEPS.RESULTS ? 'block' : 'hidden'}>
						<Results />
					</div>
				)}
			</div>
		</div>
	);
};

export default CloudProcessing;
